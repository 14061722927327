<template>
  <v-container class="overviewContainer">
    <v-row class="hidden-sm-and-down">
      <v-img
        height="300"
        src="https://res.cloudinary.com/ecrtechdev/image/upload/v1633363012/Slider-2_1_mkwcsu.png"
        style="overflow: visible"
      >
        <v-row wrap>
          <v-col cols="12" align="center">
            <p class="bigHeader">Season's Buys</p>
          </v-col>
        </v-row>

        <v-row class="mt-10" style="postion:absolute; left: 44%; top: 90% ">
          <v-col cols="3" class="headerBox ml-12" v-for="(product,index) in discountedProducts.slice(0,3)" :key="index">
            <v-layout wrap>
              <v-col cols="5" align="left">
                <v-img
                  contain
                  :src="product.productImagePath"
                  :to="'/product-detail/' + product.productId"
                  height="120"
                />
                <p class="mt-1">
                    <v-btn
                      class="buttonStyle"
                        v-show="product.needsPrescription == false"
                        align="center"
                        outlined
                        small
                        dark
                        color="#000000"
                        :to="'/product-detail/' + product.productId"
                        
                      >
                        <v-icon class="mr-2" style="font-size:14px;">
                          mdi-cart-outline
                        </v-icon>
                        Cart
                      </v-btn>
                      <v-btn
                        align="center"
                        small
                        class="buttonStyle"
                        :to="'/prescription/dashboard/en'"
                        v-if="product.needsPrescription == true"
                      >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescribed
                    </v-btn>
                  </p>
              </v-col>

              <v-col cols="7" align="right">
                <v-icon size="50" color="#72BD61">mdi-bookmark</v-icon>
                <v-layout align="right" style="font-size:11px;" class="mt-4">
                  {{ product.name }}
                  <br>
                  {{ getProductContent(product) }}
                </v-layout>
                <v-layout wrap>
                  <v-col cols="6">
                    <h6>{{ systemSetting.systemCurrency }}{{ product.price }}</h6>
                  </v-col>
                  <v-col cols="6">
                    <span class="spanPrice">-{{ product.discount }}%</span>
                  </v-col>
                </v-layout>
              </v-col>
            </v-layout>
          </v-col>
        </v-row>
      </v-img>
    </v-row>


    <v-container class="hidden-md-and-up">
      <v-row>
        <v-col cols="12">
          <h2 align="center">Season's Buys</h2>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="headerBox" v-for="(product,index) in discountedProducts.slice(0,1)" :key="index">
          <v-layout wrap>
            <v-col cols="5" align="left">
              <v-img
                contain
                :src="product.productImagePath"
                :to="'/product-detail/' + product.productId"
                height="120"
              />
                <p class="mt-1">
                    <v-btn
                      class="buttonStyle"
                        v-show="product.needsPrescription == false"
                        align="center"
                        outlined
                        small
                        dark
                        color="#000000"
                        :to="'/product-detail/' + product.productId"
                        
                      >
                        <v-icon class="mr-2" style="font-size:14px;">
                          mdi-cart-outline
                        </v-icon>
                        Cart
                      </v-btn>
                      <v-btn
                        align="center"
                        small
                        class="buttonStyle"
                        :to="'/prescription/dashboard/en'"
                        v-if="product.needsPrescription == true"
                      >
                      <v-icon class="mr-2" style="font-size:14px;">
                        mdi-pill-multiple
                      </v-icon>
                      Prescription
                    </v-btn>
                  </p>
            </v-col>

            <v-col cols="7" align="right">
              <v-icon size="50" color="#72BD61">mdi-bookmark</v-icon>
              <v-layout align="right" style="font-size:11px;" class="mt-4">
                 {{ product.name }}
              </v-layout>
              <v-layout wrap>
                <v-col cols="6">
                  <h6>{{ systemSetting.systemCurrency }}{{ product.price }}</h6>
                </v-col>
                <v-col cols="6">
                  <span class="spanPrice">-{{ product.discount }}%</span>
                </v-col>
              </v-layout>
            </v-col>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>

    <v-row class="mb-8 mt-5">
      <StoreProducts></StoreProducts>
    </v-row>
  </v-container>
</template>
<script>
import StoreProducts from "./products/StoreProducts";

export default {
  name: "Stores",

  components: {
    StoreProducts
  },

  data() {
    return {
      discountedProducts: [],
      distributorsDiscountedProducts:[],
      manufacturerDiscountedProducts:[],
      productLists: [],
      systemSetting: {
        systemCurrency: "GH₵",
      },
    };
  },
  computed: {
    fiteredDiscounts: function() {
      return this.discountedProducts.filter(function(el) {
        return el.discount >= 1;
      });
    },
  },
  created() {
    this.getProducts();
  },
  methods: {
     ///////Truncate a text based on the number of characters//////
    getProductContent(product) {
      let body = this.stripTags(product.description);

      return body.length > 40 ? body.substring(0, 40) + "..." : body;
    },

    stripTags(text) {
      return text.replace(/(<([^>]+)>)/gi, "");
    },
    getProducts() {
      this.$http
        .get(`${process.env.VUE_APP_URL}products`)
        .then((response) => {
          response.data.data.forEach(element => {
              if(element.User.Role.name == "Retailer" && element.displayDiscount == true){
                  this.discountedProducts.push(element);
              }

              if(element.User.Role.name == "Distributor" && element.displayDiscount == true){
                this.distributorsDiscountedProducts.push(element);
              }

              if(element.User.Role.name == "Manufacturer" && element.displayDiscount == true){
                this.manufacturerDiscountedProducts.push(element);
              }
          });
        });
    },
  },
};
</script>
<style scoped>
.overviewContainer {
  background-color: #ffffff;
  padding: 10px;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.bigHeader {
  font-size: 50px;
  color: #ffffff;
  margin-top: 20px;
}

.headerBox {
  border: 2px solid green;
  height: 200px;
  background-color: #ffffff;
  border-radius: 10px;
}

.headerBox2 {
  border: 1px solid green;
  height: 150px;
  background-color: #ffffff;
  border-radius: 10px;
}

.spanPrice {
  background-color: red;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: 500;
}

.categoryDeals {
  background-color: #c4c4c4;
  height: 250px;
  margin-bottom: 100px;
  overflow: visible;
}

.priceStyle2 {
  width: 60px;
  height: auto;
  background-color: #bd2121;
  color: #ffffff;
  padding: 2px;
  border-bottom-right-radius: 5px;
}
</style>
